import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () =>{
  return (<footer className="global-footer gradient-band">
              <StaticImage
        className="footer-logo"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/logo-icon.png"
        width={100}
        height={100}
        quality={95}
        alt="Dice Told Tales"
      />
      <p className="timestamp">
        © {new Date().getFullYear()} <a href="https://dicetoldtales.com/">Dice Told Tales</a>
      </p>
      </footer>)
}

export default Footer;